import OrderDetailled from "./OrderDetailled";
import OrderProduct from "./OrderProduct";
import WorkindApiClient from "../WorkindApiClient";

class OrderService extends WorkindApiClient {
  async getOrders(
    organizationId: number | undefined,
    dateStart: string | undefined = undefined,
    dateEnd: string | undefined = undefined
  ): Promise<Array<OrderDetailled>> {
    let orders: Array<OrderDetailled> | undefined = [];
    if (!dateStart) {
      orders = await this.get<Array<OrderDetailled>>(`${this.apiUrl}/organizations/${organizationId}/orders`);
    } else {
      orders = await this.get<Array<OrderDetailled>>(`${this.apiUrl}/organizations/${organizationId}/orders?dateStart=${dateStart}&dateEnd=${dateEnd}`);
    }
    if (!orders) return [];

    for (let order of orders) {
      order.date = new Date(order.date);
    }
    return orders;
  }

  async getOrderProducts(organizationId: number | undefined, orderId: number) {
    let products: Array<OrderProduct> | undefined = [];
    products = await this.get<Array<OrderProduct>>(`${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/products`);
    if (!products) return [];

    return products;
  }

  async cancelOrder(organizationId: number | undefined, userId: number, orderId: number) {
    await this.postNoResponse(`${this.apiUrl}/organizations/${organizationId}/orders/Cancel`, {
      UserId: userId,
      OrderId: orderId,
      Description: `Cancel order #${orderId}`,
    });
  }

  async cancelOrderPartially(organizationId: number | undefined, userId: number, orderId: number | undefined, productIds: number[]) {
    await this.postNoResponse(`${this.apiUrl}/organizations/${organizationId}/orders/partialCancel`, {
      UserId: userId,
      ProductIds: productIds,
      OrderId: orderId,
      Description: `Partial cancel order #${orderId}`,
    });
  }
}

const orderService = new OrderService();
export default orderService;
