import WorkindApiClient from "../WorkindApiClient";
import EntityRecord from "./EntityRecord";

class EntityService extends WorkindApiClient {
  async getTableRecords(organizationId: number, tableId: string): Promise<Array<EntityRecord>> {
    const list = await this.get<Array<EntityRecord>>(`${this.apiUrl}/organizations/${organizationId}/tables/${tableId}/records`);
    if (!list) return [];
    return list;
  }

  async createTableRecord(organizationId: number, tableId: string, record: EntityRecord) {
    try {
      const data: any = {
        id: record.id,
        values: Object.fromEntries(record.values),
      };
      await this.post<EntityRecord, string>(`${this.apiUrl}/organizations/${organizationId}/tables/${tableId}/records`, data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async updateTableRecord(organizationId: number, tableId: string, record: EntityRecord) {
    try {
      const data: any = {
        id: record.id,
        values: Object.fromEntries(record.values),
      };
      await this.put(`${this.apiUrl}/organizations/${organizationId}/tables/${tableId}/records/${record.id}`, data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async deleteTableRecord(organizationId: number, tableId: string, recordId: string) {
    try {
      await this.delete(`${this.apiUrl}/organizations/${organizationId}/tables/${tableId}/records/${recordId}`);
    } catch (error) {
      console.error("Error:", error);
    }
  }
}

const entityService = new EntityService();
export default entityService;
