import Stack from "@mui/material/Stack";
import { Box, CircularProgress, Typography } from "@mui/material";
import reportService from "../../services/Reports/ReportService";
import { Notify } from "../../components/notify";
import { Dayjs } from "dayjs";
import DateRangeSelector from "../../components/DateRangeSelector";
import { DateHelpers } from "../../services/DateHelpers";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OrganizationSelector from "../../components/OrganizationSelector";
import RolesHelpers from "../../services/Users/RolesHelpers";
import { useSessionContext } from "../../contexts/SessionContext";
import { DownloadHelpers } from "../../components/DownloadHelpers";
import { useEffect, useState } from "react";
import LoadingComponent from "../../components/LoadingComponent";

export function PayReportPage() {
  const { t } = useTranslation();
  const { user } = useSessionContext();

  const [organizationSelected, setOrganizationSelected] = useState<number>(-1);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(DateHelpers.nowDateOnly().add(-30, "day"));
  const [dateTo, setDateTo] = useState<Dayjs | null>(DateHelpers.nowDateOnly());
  const [working, setWorking] = useState<boolean>(false);

  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);

  useEffect(() => {
    if (user && !workindAdmin && user.organizations.length > 0) {
      setOrganizationSelected(user.organizations[0].id);
      console.log(`selected first org '${user.organizations[0].id}'`);
    }
  }, [user, workindAdmin]);

  const downloadPayReimbursements = async () => {
    try {
      setWorking(true);
      const result = await reportService.downloadPayReimbursements(organizationSelected, dateFrom?.toISOString(), dateTo?.toISOString());
      if (result) {
        DownloadHelpers.downloadCsvFile(result as string, `pay_reimbursements-${dateFrom?.format("YYYYMMDD")}-${dateTo?.format("YYYYMMDD")}`);
        Notify.success(t("reports.msg-success-filegen"));
      }
    } catch (error) {
      Notify.error(error + "");
    } finally {
      setWorking(false);
    }
  };

  const downloadPayTransactions = async () => {
    try {
      setWorking(true);
      const result = await reportService.downloadPayTransactions(organizationSelected, dateFrom?.toISOString(), dateTo?.toISOString());
      if (result) {
        DownloadHelpers.downloadCsvFile(result as string, `pay_transactions-${dateFrom?.format("YYYYMMDD")}-${dateTo?.format("YYYYMMDD")}`);
        Notify.success(t("reports.msg-success-filegen"));
      }
    } catch (error) {
      Notify.error(error + "");
    } finally {
      setWorking(false);
    }
  };

  const downloadAuditTransactions = async () => {
    try {
      setWorking(true);
      const result = await reportService.downloadAuditTransactions(organizationSelected, dateFrom?.toISOString(), dateTo?.toISOString());
      if (result) {
        DownloadHelpers.downloadCsvFile(result as string, `audit_transactions-${dateFrom?.format("YYYYMMDD")}-${dateTo?.format("YYYYMMDD")}`);
        Notify.success(t("reports.msg-success-filegen"));
      }
    } catch (error) {
      Notify.error(error + "");
    } finally {
      setWorking(false);
    }
  };

  const downloadClaimsReimbursed = async () => {
    try {
      setWorking(true);
      const result = await reportService.downloadClaimsReimbursed(organizationSelected, dateFrom?.toISOString(), dateTo?.toISOString());
      if (result) {
        DownloadHelpers.downloadCsvFile(result as string, `claims_reimbursed-${dateFrom?.format("YYYYMMDD")}-${dateTo?.format("YYYYMMDD")}`);
        Notify.success(t("reports.msg-success-filegen"));
      }
    } catch (error) {
      Notify.error(error + "");
    } finally {
      setWorking(false);
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={3}>
        <Typography variant="h2">{t("reports.label-reports")}</Typography>{" "}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        {workindAdmin && <OrganizationSelector allowAllSelection={false} onChange={(id) => setOrganizationSelected(id)} value={organizationSelected} />}

        <DateRangeSelector
          isDisabled={false}
          onChangeFrom={(date) => {
            setDateFrom(date);
          }}
          onChangeTo={(date) => {
            setDateTo(date);
          }}
        />
      </Stack>

      {working ? (
        <Box mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {organizationSelected >= 0 && (
            <Box mt={4}>
              <LinkReport title={t("reports.pay-reimbursements")} onClick={downloadPayReimbursements} />
              <LinkReport title={t("reports.pay-transactions")} onClick={downloadPayTransactions} />
              <LinkReport title={t("reports.pay-audit-transactions")} onClick={downloadAuditTransactions} />
              <LinkReport title={t("reports.claim-reimbursed")} onClick={downloadClaimsReimbursed} />
            </Box>
          )}
        </>
      )}
    </>
  );
}

function LinkReport(props: { title: string; onClick: () => void }) {
  return (
    <Box mb={3}>
      <Link to={""} onClick={props.onClick}>
        {props.title}
      </Link>
    </Box>
  );
}
