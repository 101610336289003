import { Box, Stack } from "@mui/system";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import { DateHelpers } from "../../services/DateHelpers";
import DateRangeSelector from "../../components/DateRangeSelector";
import { Dayjs } from "dayjs";
import FormatHelpers from "../../services/FormatHelpers";
import LoadingComponent from "../../components/LoadingComponent";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import { Modal } from "@material-ui/core";
import { Notify } from "../../components/notify";
import OrderDetailled from "../../services/Orders/OrderDetailled";
import OrderProduct from "../../services/Orders/OrderProduct";
import OrderStatusSelector from "../../components/OrderStatusSelector";
import OrganizationSelector from "../../components/OrganizationSelector";
import RolesHelpers from "../../services/Users/RolesHelpers";
import Unauthorized from "../../components/Unauthorized";
import orderService from "../../services/Orders/OrderService";
import { useSessionContext } from "../../contexts/SessionContext";
import { useTranslation } from "react-i18next";

export default function OrdersPage() {
  const lang = localStorage.getItem("language") || "en";
  const { t } = useTranslation();
  const { user } = useSessionContext();

  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [orders, setOrders] = useState<Array<OrderDetailled>>([]);
  const [sourceOrders, setSourceOrders] = useState<Array<OrderDetailled>>([]);
  const [organizationSelected, setOrganizationSelected] = useState<number>(-1);
  const [statusSelected, setStatusSelected] = useState<string>("All");
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(DateHelpers.nowDateOnly().add(-30, "day"));
  const [dateTo, setDateTo] = useState<Dayjs | null>(DateHelpers.nowDateOnly());
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalOpenPartialCancel, setModalOpenPartialCancel] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<OrderDetailled | null>(null);
  const [reloadOrders, setReloadOrders] = useState(0);
  const [orderProducts, setOrderProducts] = useState<OrderProduct[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);

  const handleCancelOpenModal = (row: OrderDetailled) => {
    setSelectedRow(row);
    setModalOpen(true);
  };

  const handleCancelCloseModal = () => {
    setReloadOrders((prev) => prev + 1);
    setModalOpen(false);
    setOrderProducts([]);
    setSelectedRow(null);
    setSelectedProducts([]);
  };

  const handleCancelPartialOpenModal = async (row: OrderDetailled) => {
    setSelectedRow(row);
    const products = await orderService.getOrderProducts(user?.user.organizationId, Number(row.id));
    setOrderProducts(products);
    setModalOpenPartialCancel(true);
  };

  const handleCancelPartialCloseModal = () => {
    setReloadOrders((prev) => prev + 1);
    setModalOpenPartialCancel(false);
    setOrderProducts([]);
    setSelectedRow(null);
    setSelectedProducts([]);
  };

  const handleProductSelection = (productId: number) => {
    setSelectedProducts((prev) => (prev.includes(productId) ? prev.filter((id) => id !== productId) : [...prev, productId]));
  };

  const handleConfirmPartialCancel = async () => {
    console.log("Selected products for refund:", selectedProducts);

    await orderService.cancelOrderPartially(user?.user.organizationId, Number(selectedRow?.userId), Number(selectedRow?.id), selectedProducts);
    handleCancelPartialCloseModal();
  };

  const handleConfirmCancel = async (row: any) => {
    console.log(row);

    await orderService.cancelOrder(user?.user.organizationId, Number(selectedRow?.userId), Number(selectedRow?.id));

    handleCancelCloseModal();
  };
  useEffect(() => {
    if (user && !workindAdmin && user.organizations.length > 0) {
      setOrganizationSelected(user.organizations[0].id);
      console.log(`selected first org '${user.organizations[0].id}'`);
    }
  }, [user, workindAdmin]);

  useEffect(() => {
    async function get() {
      try {
        if (user) {
          if (organizationSelected >= 0 || workindAdmin) {
            let orgId: number = organizationSelected;
            if (!RolesHelpers.IsOrganizationAdminFromList(user.roles)) {
              orgId = user.organizations[0].id;
            }

            const orders = await orderService.getOrders(orgId, dateFrom?.toISOString(), dateTo?.toISOString());
            console.log(`OrdersPage.useEffect(orgId=${orgId}, from=${dateFrom?.toISOString()}, to=${dateTo?.toISOString()}) --> ${orders.length}`);
            setSourceOrders(orders);
            setLoading(LoadingStatus.Success);
          }
        }
      } catch (e) {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("admin.orders.error-loading"));
      }
    }

    get();
  }, [user, organizationSelected, dateFrom, dateTo, reloadOrders]);

  useEffect(() => {
    if (statusSelected === "All") {
      setOrders(sourceOrders);
    } else {
      const result = sourceOrders.filter((o) => o.status === statusSelected);
      setOrders(result);
    }
  }, [sourceOrders, statusSelected]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", type: "string" },
    {
      field: "date",
      headerName: "Date",
      type: "dateTime",
      minWidth: 140,
      valueFormatter: ({ value }) => FormatHelpers.formatDateTimeShort(value),
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      valueFormatter: ({ value }) => t("order.status-" + value),
    },
    {
      field: "name",
      headerName: t("common.user"),
      type: "string",
      minWidth: 140,
      valueGetter: ({ row }) => `${row.firstname} ${row.lastname || ""}`,
      flex: 1,
    },
    { field: "email", headerName: t("common.email"), type: "string", minWidth: 200, flex: 1 },
    { field: "organizationName", headerName: t("common.organization"), type: "string", minWidth: 100, flex: 1 },
    {
      field: "total",
      headerName: t("admin.orders.total"),
      type: "string",
      minWidth: 100,
      valueFormatter: ({ value }) => FormatHelpers.formatMoney(value),
      flex: 1,
    },
    {
      field: "creditUsed",
      headerName: t("admin.orders.credit-used"),
      type: "string",
      minWidth: 140,
      disableExport: true,
      valueFormatter: ({ value }) => {
        return FormatHelpers.formatMoney(value);
      },
      flex: 1,
    },
    {
      field: "total_raw",
      flex: 1,
      align: "center",
      minWidth: 140,
      headerName: t("admin.orders.total"),
      type: "string",
      valueGetter: ({ row }) => {
        return row.total;
      },
    },
    {
      field: "creditused_raw",
    },

    {
      field: "actions",
      headerName: t("admin.orders.actions"),
      renderCell: ({ row }) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <button
            onClick={() => handleCancelOpenModal(row)}
            disabled={row.status !== "paid"}
            style={{
              padding: "5px 10px",
              backgroundColor: row.status === "paid" ? "#007BFF" : "#d6d6d6",
              color: row.status === "paid" ? "white" : "#a1a1a1",
              border: "none",
              borderRadius: "4px",
              cursor: row.status === "paid" ? "pointer" : "not-allowed",
            }}
          >
            {t("admin.orders.cancel-order")}
          </button>
          <button
            onClick={() => handleCancelPartialOpenModal(row)}
            disabled={row.status !== "paid"}
            style={{
              padding: "5px 10px",
              backgroundColor: row.status === "paid" ? "#007BFF" : "#d6d6d6",
              color: row.status === "paid" ? "white" : "#a1a1a1",
              border: "none",
              borderRadius: "4px",
              cursor: row.status === "paid" ? "pointer" : "not-allowed",
            }}
          >
            {t("admin.orders.partially-cancel")}
          </button>
        </div>
      ),
      minWidth: 200,
      flex: 1,
    },
  ];

  function CustomToolbar() {
    let fileName = "orders";
    if (dateFrom && dateTo) {
      fileName = `orders_${dateFrom.format("YYYY-MM-DD")}-${dateTo.format("YYYY-MM-DD")}`;
    }

    return (
      <GridToolbarContainer>
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarQuickFilter />
        <GridCsvExportMenuItem
          options={{
            fileName: fileName,
            fields: ["id", "date", "status", "name", "email", "organizationName", "total_raw", "creditused_raw", "accountbalance_raw", "actions"],
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  const total = orders.reduce((sum, order) => sum + order.total, 0);
  const totalCreditUsed = orders.reduce((sum, order) => sum + order.creditUsed, 0);

  if (!workindAdmin) {
    return <Unauthorized />;
  }

  if (loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Modal open={modalOpen} onClose={handleCancelCloseModal} aria-labelledby="refund-confirmation-title" aria-describedby="refund-confirmation-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="refund-confirmation-title" variant="h6" component="h2" gutterBottom>
            {t("admin.orders.cancel-header")}
          </Typography>
          <Typography id="refund-confirmation-description" sx={{ mb: 2 }}>
            {t("admin.orders.cancel-desc")}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button onClick={handleCancelCloseModal} color="secondary" variant="outlined">
              {t("common.no")}
            </Button>
            <Button onClick={handleConfirmCancel} color="primary" variant="contained">
              {t("common.yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={modalOpenPartialCancel} onClose={handleCancelPartialCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t("admin.orders.cancel-partially")}
          </Typography>
          {orderProducts?.map((product) => (
            <FormControlLabel
              key={product.id}
              control={<Checkbox checked={selectedProducts.includes(Number(product.id))} onChange={() => handleProductSelection(Number(product.id))} />}
              label={(lang === "fr" ? product.title.fr : product.title.en) + " - " + product.price + "$"}
            />
          ))}
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 2 }}>
            <Button onClick={handleCancelPartialCloseModal} color="secondary" variant="outlined">
              {t("common.no")}
            </Button>
            <Button onClick={handleConfirmPartialCancel} color="primary" variant="contained">
              {t("common.yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Stack direction="row" alignItems="center" spacing={1} mb={3}>
        <Typography variant="h2">{t("admin.orders.title")}</Typography>{" "}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        {workindAdmin && <OrganizationSelector allowAllSelection={true} onChange={(id) => setOrganizationSelected(id)} value={organizationSelected} />}
        <OrderStatusSelector onChange={(status) => setStatusSelected(status)} />
        <DateRangeSelector
          isDisabled={false}
          onChangeFrom={(date) => {
            setDateFrom(date);
          }}
          onChangeTo={(date) => {
            setDateTo(date);
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" spacing={3} my={3}>
        <Typography color="inherit">{t("admin.orders.total")}:</Typography>
        <Typography color="inherit" sx={{ fontWeight: "bold" }}>
          {FormatHelpers.formatMoney(total)}
        </Typography>
        <Typography color="inherit">{t("admin.orders.credit-used")}:</Typography>
        <Typography color="inherit" sx={{ fontWeight: "bold" }}>
          {FormatHelpers.formatMoney(totalCreditUsed)}
        </Typography>
      </Stack>

      <DataGrid
        rows={orders}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              total_raw: false,
              creditused_raw: false,
              accountbalance_raw: false,
            },
          },
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        disableRowSelectionOnClick
        pageSizeOptions={[25]}
        localeText={{
          toolbarQuickFilterPlaceholder: t("common.search"),
          toolbarExport: t("common.export"),
          toolbarExportCSV: t("common.datagrid-downloadcsv"),
          toolbarExportPrint: t("common.datagrid-print"),
          noRowsLabel: t("common.datagrid-norows"),
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => t("common.datagrid-pagination-count", { from, to, count }),
          },
        }}
      />
    </>
  );
}
