import "dayjs/locale/fr-ca";

import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dashboard from "./admin/dashboard/Dasboard";
import ImportUsersPage from "./admin/pages/ImportUsersPage";
import Layout from "./admin/layout/Layout";
import LoadingComponent from "./components/LoadingComponent";
import { LocalizationProvider } from "@mui/x-date-pickers";
import OrdersPage from "./admin/pages/OrdersPage";
import OrganizationPage from "./admin/pages/OrganizationPage";
import OrganizationsPage from "./admin/pages/OrganizationsPage";
import RolesHelpers from "./services/Users/RolesHelpers";
import { PayReportPage } from "./admin/pages/PayReportPage";
import Unauthorized from "./components/Unauthorized";
import { UserBankDataForm } from "./userBankData";
import UserDashboard from "./userDashboard/userDashboard";
import UserProfilePage from "./admin/pages/UserProfilePage";
import UsersPage from "./admin/pages/UsersPage";
import WalletPage from "./pages/wallet/WalletPage";
import WalletsPage from "./pages/wallet/WalletsPage";
import { useSessionContext } from "./contexts/SessionContext";

function App() {
  const { user, role } = useSessionContext();
  const [hasTimeout, setHasTimeout] = useState<boolean>(false);
  const { pathname } = useLocation();

  const lng = user?.user.language === "french" ? "fr" : "en";

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!user) {
        setHasTimeout(true);
      }
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [user]);

  if (!user) {
    if (hasTimeout) return <Unauthorized />;
    else return <LoadingComponent />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={`${lng}-ca`}>
      {pathname.includes("/user") && (
        <Routes>
          <Route path="/user" element={<UserDashboard />}></Route>
          <Route path="/user/bank" element={<UserBankDataForm />}></Route>
        </Routes>
      )}
      {pathname.includes("/wallets") && (
        <Routes>
          <Route path="/wallets/:walletId" element={<WalletPage />}></Route>
          <Route path="/wallets" element={<WalletsPage />}></Route>
        </Routes>
      )}
      {pathname.includes("/admin") && (
        <Layout>
          <Routes>
            {RolesHelpers.IsOrganizationAdmin(role) && (
              <>
                <Route path="/admin" element={<Dashboard />}></Route>

                <Route path="/admin/orders" element={<OrdersPage />}></Route>

                <Route path="/admin/users" element={<UsersPage />}></Route>
                <Route path="/admin/users/import" element={<ImportUsersPage />}></Route>
                <Route path="/admin/users/:organizationId?" element={<UsersPage />}></Route>
                <Route path="/admin/users/:organizationId/:idUser" element={<UserProfilePage />}></Route>

                <Route path="/admin/pay-reports" element={<PayReportPage />}></Route>

                <Route path="/admin/organizations" element={<OrganizationsPage />}></Route>
                <Route path="/admin/organizations/:organizationId" element={<OrganizationPage />}></Route>
              </>
            )}
          </Routes>
        </Layout>
      )}
    </LocalizationProvider>
  );
}

export default App;
