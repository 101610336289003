import { useEffect, useState } from "react";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import EntityRecord from "./EntityRecord";
import { Notify } from "../../components/notify";
import { t } from "i18next";
import entityService from "./EntityService";

export default function useTableRecords(organizationId?: number, tableId?: string) {
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.None);
  const [records, setRecords] = useState<EntityRecord[]>([]);

  useEffect(() => {
    refresh();
  }, [organizationId, tableId]);

  async function refresh() {
    try {
      if (organizationId && tableId) {
        setLoading(LoadingStatus.Loading);

        const list = await entityService.getTableRecords(organizationId, tableId);
        setRecords(list);

        setLoading(LoadingStatus.Success);
      }
    } catch (e) {
      setLoading(LoadingStatus.Failure);
      Notify.error(t("admin.users.error-loading"));
    }
  }

  return {
    loading,
    records,
    refresh,
  };
}
