import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";
import { useParams } from "react-router-dom";
import { useSessionContext } from "../../contexts/SessionContext";
import { t } from "i18next";
import Organization from "../../services/Organizations/Organization";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EntityTable from "../../services/DynamicEntity/EntityTable";
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import i18n from "../../services/i18n";
import { FieldType } from "../../services/DynamicEntity/FieldType";
import EntityRecord from "../../services/DynamicEntity/EntityRecord";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import LoadingComponent from "../../components/LoadingComponent";
import { Notify } from "../../components/notify";
import entityService from "../../services/DynamicEntity/EntityService";
import FormatHelpers from "../../services/FormatHelpers";
import useTableRecords from "../../services/DynamicEntity/useTableRecords";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../../components/ConfirmDialog";

function WorkindTextField(props: any) {
  return <TextField variant="standard" size="small" {...props} />;
}

export default function OrganizationPage() {
  const { user } = useSessionContext();
  const { organizationId } = useParams<string>();

  const [organization, setOrganization] = useState<Organization | undefined>(undefined);

  const [selectedTab, setSelectedTab] = useState<string>("1");
  const onTabSelected = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (organizationId) {
      const orgId = parseInt(organizationId);
      const org = user?.organizations.find((o) => o.id === orgId);
      setOrganization(org);
    }
  }, [organizationId, user]);

  if (!organization) {
    return t("admin.user-notfound");
  }

  return (
    <>
      <PageHeader returnUrl={`/admin/organizations`} title={organization.name} />

      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={onTabSelected}>
            <Tab label={t("common.info")} value="1" />
            <Tab label={t("admin.organization.tables")} value="2" />
          </TabList>
        </Box>

        {/* Informations */}
        <TabPanel value="1">
          <PageSection>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item lg={3}>
                <WorkindTextField label={t("admin.organization.id")} disabled value={organization.id} fullWidth />
              </Grid>
              <Grid item lg={3}>
                <WorkindTextField label={t("admin.organization.name")} disabled value={organization.name} fullWidth />
              </Grid>
            </Grid>

            <Grid container spacing={2} marginBottom={2}>
              <Grid item lg={3}>
                <WorkindTextField label={t("admin.organization.accountId")} disabled value={organization.accountId} fullWidth />
              </Grid>
              <Grid item lg={3}>
                <WorkindTextField
                  label={t("admin.organization.useReferenceIdHasExternalKey")}
                  disabled
                  value={organization.useReferenceIdHasExternalKey}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} marginBottom={2}>
              <Grid item lg={6}>
                <WorkindTextField label={t("admin.organization.features")} disabled value={organization.features} fullWidth />
              </Grid>
            </Grid>
          </PageSection>
        </TabPanel>

        {/* Tables */}
        <TabPanel value="2">
          <PageSection>
            <OrganizationTables organization={organization} />
          </PageSection>
        </TabPanel>
      </TabContext>
    </>
  );
}

function OrganizationTables({ organization }: { organization: Organization }) {
  const tables = organization.tables;

  const [tableId, setTableId] = useState<string>("");

  const handleTableSelectionChange = (event: SelectChangeEvent) => {
    setTableId(event.target.value as string);
  };

  const selectedTable = tables.find((t) => t.id === tableId);

  return (
    <>
      <Stack direction="row" alignItems="center" mb={2}>
        <InputLabel id="table" sx={{ marginRight: 2 }}>
          {t("admin.organization.tables")}
        </InputLabel>
        <Select labelId="table" value={tableId} onChange={handleTableSelectionChange} sx={{ minWidth: 300 }}>
          <MenuItem value=""></MenuItem>
          {tables
            .filter((t) => t.id !== "UserData")
            .map((table) => {
              return (
                <MenuItem key={table.id} value={table.id}>
                  {table.id}
                </MenuItem>
              );
            })}
        </Select>
      </Stack>

      {selectedTable && <OrganizationTable organizationId={organization.id} table={selectedTable} />}
    </>
  );
}

function OrganizationTable({ organizationId, table }: { organizationId: number; table: EntityTable }) {
  const { loading, refresh, records } = useTableRecords(organizationId, table.id);

  const [recordInEdit, setRecordInEdit] = useState<EntityRecord | undefined>(undefined);
  const [recordInDelete, setRecordInDelete] = useState<EntityRecord | undefined>(undefined);

  const createRecord = () => {
    setRecordInEdit({ values: new Map<string, any>() });
  };

  const editRecord = (record: EntityRecord) => {
    const values = new Map<string, any>(Object.entries(record.values));
    setRecordInEdit({ id: record.id, values: values });
  };

  const deleteRecord = async () => {
    if (recordInDelete) {
      try {
        await entityService.deleteTableRecord(organizationId, table.id, recordInDelete.id!);
        Notify.success(t("admin.organization.delete-success"));

        refresh();
        setRecordInDelete(undefined);
      } catch {
        Notify.error(t("admin.organization.error-save"));
      }
    }
  };

  const saveRecord = async () => {
    if (!recordInEdit) return;

    try {
      if (recordInEdit.id) {
        await entityService.updateTableRecord(organizationId, table.id, recordInEdit);
        Notify.success(t("admin.organization.edit-success"));
      } else {
        await entityService.createTableRecord(organizationId, table.id, recordInEdit);
        Notify.success(t("admin.organization.create-success"));
      }

      setRecordInEdit(undefined);
      refresh();
    } catch {
      Notify.error(t("admin.organization.error-save"));
    }
  };

  if (loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      type: "string",
      minWidth: 70,
    },
  ];

  const fieldKeys = Array.from(table.fields.keys());
  const columnsToExport: string[] = ["Id"];
  for (const fieldId of fieldKeys) {
    const field = table.fields.get(fieldId);
    if (field) {
      columns.push({
        field: fieldId,
        headerName: field.display[i18n.language],
        type: field.type === FieldType.Number ? "number" : "string",
        minWidth: 110,
        valueGetter: (params) => {
          let value = params.row.values[params.field];

          if (!value) {
            return "";
          }

          if (field.type === FieldType.Datetime) value = FormatHelpers.formatDateShort(new Date(value));

          return value;
        },
      });

      columnsToExport.push(fieldId);
    }
  }

  columns.push({
    field: "action",
    headerName: "",
    sortable: false,
    renderCell: (params) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
        editRecord(params.row);
      };

      const onClickDelete = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
        setRecordInDelete(params.row);
      };

      return (
        <>
          <IconButton color="primary" size="small" onClick={onClickEdit}>
            <EditIcon />
          </IconButton>
          <IconButton color="primary" size="small" onClick={onClickDelete}>
            <DeleteIcon />
          </IconButton>
        </>
      );
    },
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridCsvExportMenuItem options={{ fileName: `${table.id}.csv`, fields: columnsToExport, utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Box mb={2}>
        <Button variant="contained" onClick={createRecord}>
          {t("common.create")}
        </Button>
      </Box>

      <DataGrid
        rows={records}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        disableRowSelectionOnClick
        pageSizeOptions={[25]}
        localeText={{
          toolbarQuickFilterPlaceholder: t("common.search"),
          toolbarExport: t("common.export"),
          toolbarExportCSV: t("common.datagrid-downloadcsv"),
          toolbarExportPrint: t("common.datagrid-print"),
          noRowsLabel: t("common.datagrid-norows"),
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => t("common.datagrid-pagination-count", { from, to, count }),
          },
        }}
      />

      {recordInEdit && (
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={recordInEdit !== undefined}
          onClose={() => {
            setRecordInEdit(undefined);
          }}
        >
          <DialogContent>
            {recordInEdit?.id && (
              <Stack direction="row" alignItems="center" mb={1}>
                <Typography minWidth={200}>{t("common.id")}</Typography>
                <TextField disabled sx={{ flexGrow: 1 }} value={recordInEdit?.id}></TextField>
              </Stack>
            )}

            {fieldKeys.map((key, index) => {
              const field = table.fields.get(key);
              if (!field) {
                return <></>;
              }

              return (
                <Stack key={index} direction="row" alignItems="center" mb={1}>
                  <Typography minWidth={200}>{field.display[i18n.language]}</Typography>
                  <TextField
                    sx={{ flexGrow: 1 }}
                    value={recordInEdit.values.get(key)}
                    onChange={(e: any) => {
                      const newValue = e.target.value;
                      setRecordInEdit(() => ({
                        ...recordInEdit,
                        values: new Map(recordInEdit.values.set(key, newValue)),
                      }));
                    }}
                  ></TextField>
                </Stack>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRecordInEdit(undefined)}>{t("common.cancel")}</Button>
            <Button onClick={saveRecord}>{t(recordInEdit?.id ? "common.edit" : "common.create")}</Button>
          </DialogActions>
        </Dialog>
      )}

      <ConfirmDialog
        title={t("common.delete")}
        content={t("admin.organization.delete-msg")}
        onOk={deleteRecord}
        onCancel={() => setRecordInDelete(undefined)}
        open={recordInDelete !== undefined}
      />
    </>
  );
}
