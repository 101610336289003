import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { t } from "i18next";

export default function ConfirmDialog(props: {
  open: boolean;
  title: string;
  content: string;
  okText?: string;
  cancelText?: string;
  onOk: () => void;
  onCancel: () => void;
}) {
  return (
    <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="confirm-dialog-title" aria-describedby="confirm-dialog-description">
      <DialogTitle id="confirm-dialog-title">{props.title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="confirm-dialog-description">{props.content}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onOk}>{props.okText ?? t("common.yes")}</Button>
        <Button onClick={props.onCancel} autoFocus>
          {props.cancelText ?? t("common.no")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
