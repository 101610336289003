import WorkindApiClient from "../WorkindApiClient";

class ReportService extends WorkindApiClient {
  async downloadPayReimbursements(
    organizationId: number,
    dateStart: string | undefined = undefined,
    dateEnd: string | undefined = undefined
  ): Promise<String | undefined> {
    try {
      const result = await this.get<String>(
        `${this.apiUrl}/organizations/${organizationId}/reports/pay/reimbursements?dateStart=${dateStart}&dateEnd=${dateEnd}&format=csv`
      );
      return result;
    } catch {
      return undefined;
    }
  }

  async downloadPayTransactions(
    organizationId: number,
    dateStart: string | undefined = undefined,
    dateEnd: string | undefined = undefined
  ): Promise<String | undefined> {
    try {
      const result = await this.get<String>(
        `${this.apiUrl}/organizations/${organizationId}/reports/pay/transactions?dateStart=${dateStart}&dateEnd=${dateEnd}&format=csv`
      );
      return result;
    } catch {
      return undefined;
    }
  }

  async downloadAuditTransactions(
    organizationId: number,
    dateStart: string | undefined = undefined,
    dateEnd: string | undefined = undefined
  ): Promise<String | undefined> {
    try {
      const result = await this.get<String>(
        `${this.apiUrl}/organizations/${organizationId}/reports/pay/audit-transactions?dateStart=${dateStart}&dateEnd=${dateEnd}&format=csv`
      );
      return result;
    } catch {
      return undefined;
    }
  }

  async downloadClaimsReimbursed(
    organizationId: number,
    dateStart: string | undefined = undefined,
    dateEnd: string | undefined = undefined
  ): Promise<String | undefined> {
    try {
      const result = await this.get<String>(
        `${this.apiUrl}/organizations/${organizationId}/reimbursements/reimbursed?dateStart=${dateStart}&dateEnd=${dateEnd}&format=csv`
      );
      return result;
    } catch {
      return undefined;
    }
  }
}

const reportService = new ReportService();
export default reportService;
